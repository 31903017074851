export enum PERIOD {
    DAILY = 'daily',
    WEEKLY = 'weekly',
    CUSTOM = 'custom',
}
export enum ReportType {
    BRAND = 'brand',
    RESTAURANT = 'restaurant',
}
export enum TIME_RANGE_TEXT {
    today = 'Today',
    yesterday = 'Yesterday',
    week = 'This Week',
    month = 'This Month',
    last30Days = 'Last 30 Days',
    quarter = 'This Quarter',
    custom = 'custom',
    all = 'All',
}

export interface IFetchDashboardDataResponse {
    cards: ICardData[];
    currencySymbol: string;
    currencyCode: string;
}
export interface ICardData {
    value: number | string;
    title: string;
}

export enum DashboardPeriodFilterEnum {
    TODAY = 'today',
    YESTERDAY = 'yesterday',
    WEEK = 'week',
    LAST_7_DAYS = 'last_7_days',
    MONTH = 'month',
    LAST_30_DAYS = 'last_30_days',
    CUSTOM = 'custom',
}

export interface IRevenueData {
    total_paid_amount: string;
    total_tip_amount: string;
    total_voucher_amount: string;
    total_loyalty_amount: string;
    currency: {
        currency_code: string;
        currency_symbol: string;
    };
}
export interface IRefundData {
    total_refund_amount: string;
    currency: {
        currency_code: string;
        currency_symbol: string;
    };
}
export interface IOrderCount {
    today: number;
    week: number;
    orders?: number;
}

export interface IDashboard {
    refundData: IRefundData;
    dailyRefundData: IRefundData;
    revenueData: IRevenueData;
    dailyRevenueData: IRevenueData;
    orderCount: IOrderCount;
    dailyOrderCount: number | any;
    dinerFeeData: string;
    dailyDinerFeeData: string;
    revenueWowData: number;
    orderWowData: number;
    ordersComparedLastWeek: number;
}
export const initialRevenueReportState: IDashboard = {
    refundData: {
        total_refund_amount: '0',
        currency: {
            currency_code: '',
            currency_symbol: '',
        },
    },
    dailyRefundData: {
        total_refund_amount: '',
        currency: {
            currency_code: '',
            currency_symbol: '',
        },
    },
    revenueData: {
        total_paid_amount: '',
        total_tip_amount: '',
        total_voucher_amount: '',
        total_loyalty_amount: '',
        currency: {
            currency_code: '',
            currency_symbol: '',
        },
    },
    dailyRevenueData: {
        total_paid_amount: '',
        total_tip_amount: '',
        total_voucher_amount: '',
        total_loyalty_amount: '',
        currency: {
            currency_code: '',
            currency_symbol: '',
        },
    },
    orderCount: {
        today: 0,
        week: 0,
    },
    dailyOrderCount: 0,
    dinerFeeData: '0',
    dailyDinerFeeData: '0',
    revenueWowData: 0,
    orderWowData: 0,
    ordersComparedLastWeek: 0,
};

export interface LoadingStates {
    [key: string]: boolean;
}
export interface APIError {
    message: string;
}

export type ErrorKey = string;

export interface ErrorState {
    [key: string]: string;
}
