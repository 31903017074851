import React, { FC } from 'react';
import { Typography } from '@mui/material';
import Loading from '../Loading';

type IProps = {
    loading: boolean;
    error: string;
    children: any;
    skeleton: any;
};

const SkeletonLoadingContent: FC<IProps> = ({ loading, error, children, skeleton }) => {
    return loading ? (
        skeleton || <Loading />
    ) : error ? (
        <Typography
            sx={{
                textAlign: 'center',
                padding: '5rem 0',
            }}
            color="error"
        >
            {error}
        </Typography>
    ) : (
        children
    );
};

export default SkeletonLoadingContent;
