import { Box, Skeleton, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Info } from '@mui/icons-material';
import { useRestaurantContext } from '@/contexts/restaurant';
import { Section } from '../common';

export interface IDashboardCardProps {
    headerText: string;
    value: string;
    valueIcon?: any;
    tooltipText: string;
    loading: boolean;
    error: string;
    type?: string;
}

export default function DashboardCard({
    headerText,
    value,
    valueIcon,
    tooltipText,
    loading,
    error,
    type,
}: IDashboardCardProps) {
    const theme = useTheme();
    const downLg = useMediaQuery(theme.breakpoints.down(900));
    const downXs = useMediaQuery(theme.breakpoints.down(600));
    const { restaurant } = useRestaurantContext();

    if (loading) {
        return (
            <Section
                sx={{
                    border: '1px solid #E5E5E5',
                    backgroundColor: '#FAFAFA',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography
                        sx={{
                            fontSize: downLg ? '2.1vw' : '0.8vw',
                            minHeight: '3vh',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                        }}
                        noWrap={false}
                    >
                        <Skeleton variant="text" width={downLg && !downXs ? 200 : downXs ? 100 : 70} />
                    </Typography>
                    {restaurant?.config?.dashboardTooltipEnabled && (
                        <Skeleton variant="circular" width={24} height={24} />
                    )}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                        sx={{
                            fontSize: downLg ? '4vw' : '1.5vw',
                            minHeight: '3vh',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            color: 'primary.main',
                        }}
                        style={{ wordWrap: 'break-word' }}
                    >
                        <Skeleton variant="text" width={80} />
                    </Typography>
                </Box>
            </Section>
        );
    }

    return (
        <Section
            sx={{
                border: '1px solid #E5E5E5',
                backgroundColor: type === 'outlined' ? '#FFFFFF' : '#FAFAFA',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                borderRadius: '8px',
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography
                    sx={{
                        fontSize: downLg ? '2.1vw' : '0.8vw',
                        minHeight: '3vh',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                    noWrap={false}
                >
                    {headerText}
                </Typography>
                {restaurant?.config?.dashboardTooltipEnabled && (
                    <Tooltip
                        title={
                            <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>{tooltipText || ''}</Typography>
                        }
                        placement="bottom-start"
                        arrow
                        enterTouchDelay={0}
                        enterDelay={0}
                    >
                        <Info sx={{ color: '#3B3B3B', fontSize: downXs ? '4vw' : downLg ? '4vw' : '1.5vw' }} />
                    </Tooltip>
                )}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {error && (
                    <Tooltip title={<Typography sx={{ fontSize: '12px', fontWeight: '500' }}>{error}</Typography>}>
                        <Info sx={{ color: 'error.main', fontSize: downXs ? '4vw' : downLg ? '4vw' : '1.5vw' }} />
                    </Tooltip>
                )}
                <Typography
                    sx={{
                        fontSize: downLg ? '4vw' : '1.5vw',
                        minHeight: '3vh',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                    color={error ? 'error' : 'primary.main'}
                    style={{ wordWrap: 'break-word' }}
                >
                    {value}
                    {!error && valueIcon}
                </Typography>
            </Box>
        </Section>
    );
}
