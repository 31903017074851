import { TFunction } from 'i18next';

export const dashboardItemsData = ({
    range,
    selectedDasboardKeys,
    showQlubDinerFee,
    showTipCard,
    t,
    isLegacy,
}: {
    range: string;
    selectedDasboardKeys: string[];
    showQlubDinerFee: boolean;
    showTipCard: boolean;
    t: TFunction;
    isLegacy: boolean;
}) =>
    [
        {
            key: 'revenue',
            name: 'Revenue',
            dateFilterText: `${t(`Revenue`)} ${range}`,
            tooltipKey: t('Total_Revenue_Selected_Period_TooltipText'),
            enabled: true,
        },
        {
            key: 'discounts',
            name: 'Discount',
            dateFilterText: `${t(`Discount`)} ${range}`,
            tooltipKey: t('Total_Discount_Selected_Period_TooltipText'),
            enabled: false,
        },
        {
            key: 'tips',
            name: 'Tips',
            dateFilterText: `${t(`Tips`)} ${range}`,
            tooltipKey: t('Total_Tip_Amount_Selected_Period_TooltipText'),
            enabled: showTipCard,
        },
        {
            key: 'orders',
            name: 'Orders',
            dateFilterText: isLegacy ? `${t(`Transactions`)} ${range}` : `${t(`Orders`)} ${range}`,
            tooltipKey: t('Total_Orders_Selected_Period_TooltipText'),
            enabled: true,
        },
        {
            key: 'refunds',
            name: 'Refunds',
            dateFilterText: `${t(`Refunds`)} ${range}`,
            tooltipKey: t('Total_Refunds_Selected_Period_TooltipText'),
            enabled: true,
        },
        {
            key: 'dinerFee',
            name: 'Diner Fee',
            dateFilterText: `${t(`Diner Fee`)} ${range}`,
            tooltipKey: t('qlub_Diner_Fee_This_Week_TooltipText'),
            enabled: showQlubDinerFee,
        },
    ].map((item) => {
        if (item.key === 'tips' && !showTipCard) return { ...item, enabled: false, valueIcon1: null };
        return {
            ...item,
            enabled: selectedDasboardKeys.length === 0 ? item.enabled : selectedDasboardKeys.includes(item.key),
            valueIcon1: null,
        };
    });
