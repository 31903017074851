import { useRestaurantContext } from '@/contexts/restaurant';
import DashboardService from '@/services/dashboard';
import { SwipeableDrawer } from '@mui/material';
import { useEffect, useState } from 'react';
import { getCurrencyWithAmount } from '@/common/utility';
import { useTranslation } from '@/hooks/translations';
import { onPushEvent } from '@/services/lib/gtm';
import { ITimeRangeForm } from '../TimeRange/Form/types';
import { CustomButton, Section } from '../common';

interface ICardDetailsData {
    [key: string]: {
        cards: {
            title: string;
            value: string;
        }[];
    };
}

interface CardTotals {
    [brand: string]: number;
}

export default function CardDetailsDrawer({
    seeMoreDetails,
    setSeeMoreDetails,
    timeRange,
}: {
    seeMoreDetails: boolean;
    setSeeMoreDetails: (value: boolean) => void;
    timeRange: ITimeRangeForm;
}) {
    const dashboardService = DashboardService.getInstance();

    const { t } = useTranslation('common');

    const { restaurant } = useRestaurantContext();
    const [cardTotals, setCardTotals] = useState<CardTotals>();
    const [loading, setLoading] = useState<boolean>(false);

    const { currency_code, currency_symbol } = restaurant?.restaurant_country || {
        currency_code: 'aed',
        currency_symbol: 'AED',
    };

    useEffect(() => {
        setCardTotals(undefined);
        setLoading(true);
        dashboardService
            .getDashboardCardDetails(restaurant?.id || '', timeRange?.type || 'month')
            .then((res) => {
                const calculateTotals = (data: ICardDetailsData) => {
                    const totals: { [key: string]: number } = {};
                    Object.keys(data).forEach((brand) => {
                        totals[brand] = data[brand].cards
                            .filter((card) => card.title === 'revenue' || card.title === 'tips')
                            .reduce((sum, card) => sum + parseFloat(card.value), 0);
                    });
                    return totals;
                };

                setCardTotals(calculateTotals(res.data));
            })
            .finally(() => setLoading(false));
    }, [timeRange, seeMoreDetails]);
    return (
        <>
            <CustomButton
                onClick={() => {
                    setSeeMoreDetails(true);
                    onPushEvent('user_click_on_see_more_details');
                }}
                size="large"
                sx={{ color: 'primary.main', width: '100%', textTransform: 'none' }}
                disabled={cardTotals ? Object.keys(cardTotals).length === 0 : true}
            >
                {t('Details')}
            </CustomButton>
            <SwipeableDrawer
                anchor="right"
                open={seeMoreDetails}
                onClose={() => setSeeMoreDetails(false)}
                onOpen={() => setSeeMoreDetails(true)}
                style={{ minWidth: '400px' }}
            >
                <div style={{ minWidth: '400px', padding: '20px' }}>
                    <h1>{t('Details')}</h1>
                </div>

                {cardTotals && Object.keys(cardTotals).length > 0 ? (
                    <Section
                        sx={{
                            border: '1px solid #E5E5E5',
                            backgroundColor: '#FAFAFA',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            margin: '8px',
                        }}
                    >
                        <h2>{t('Valor')}</h2>
                        {Object.keys(cardTotals).map((key, index) => {
                            return (
                                <div
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        height: '36px',
                                    }}
                                >
                                    <p>{t(key)}</p>
                                    <p>{getCurrencyWithAmount(cardTotals[key], currency_symbol, currency_code)}</p>
                                </div>
                            );
                        })}
                    </Section>
                ) : loading ? (
                    <Section
                        sx={{
                            border: '1px solid #E5E5E5',
                            backgroundColor: '#FAFAFA',

                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <p>
                                <span>{t('Loading')}</span>
                            </p>
                        </div>
                    </Section>
                ) : (
                    <Section
                        sx={{
                            border: '1px solid #E5E5E5',
                            backgroundColor: 'FAFAFA',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            margin: '8px',
                        }}
                    >
                        <h5>{t('There is nothing to display!')}</h5>
                    </Section>
                )}
            </SwipeableDrawer>
        </>
    );
}
