import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import randomColor from 'randomcolor';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, LineChart, Line, CartesianGrid } from 'recharts';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import DashboardService from '@/services/dashboard';
import { onPushEvent } from '@/services/lib/gtm';
import { getCurrencyPrecision } from '@/services/utils/k_currency';
import { useTranslation } from '@/hooks/translations';
import { useUserContext } from '@/contexts/user';
import { useRestaurantContext } from '@/contexts/restaurant';
import {
    commafy,
    covertUtcToRestaurantDateTime,
    getCurrencyWithAmount,
    ReportType,
    shouldCommafy,
} from '@/common/utility';
import { Section, TableContent } from '@/components/common';
import { FilterProps } from '@/views/Dashboard';
import SkeletonLoadingContent from './SkeletonLoadingContent';

type Props = {
    type: ReportType;
};

interface RestaurantRowProps {
    data: any;
    name: string;
    color: string;
    currencySymbol: any;
    currencyCode: string;
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const RestaurantRow = ({ data, name, color, currencySymbol, currencyCode }: RestaurantRowProps) => {
    const totalRevenue = data.reduce((a: number, b: any) => a + b.revenue, 0.0);
    const totalRevenueWithTip = data.reduce((a: number, b: any) => a + b.revenue_with_tip, 0.0);
    const totalTip = totalRevenueWithTip - totalRevenue;
    return (
        <StyledTableRow>
            <TableCell sx={{ borderBottom: 'none' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                        sx={{
                            width: '1.5rem',
                            height: '1.5rem',
                            borderRadius: '0.3rem',
                            backgroundColor: color,
                            marginRight: '1rem',
                        }}
                    />
                    <Typography>{name}</Typography>
                </Box>
            </TableCell>
            <TableCell sx={{ borderBottom: 'none' }}>
                {getCurrencyWithAmount(totalRevenue, currencySymbol, currencyCode, true)}
            </TableCell>
            <TableCell sx={{ borderBottom: 'none' }}>
                {getCurrencyWithAmount(totalTip.toString(), currencySymbol, currencyCode, true)}
            </TableCell>
        </StyledTableRow>
    );
};

export default function RevenueChart({ timeRange, type }: FilterProps & Props) {
    const dashboardService = DashboardService.getInstance();
    const { t } = useTranslation('common');
    const router = useRouter();
    const { lang } = router.query as { lang?: string };
    const [chartData, setChartData] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
    const { user } = useUserContext();
    const { restaurant, selectedProductType } = useRestaurantContext();
    const [chartMode, setChartMode] = useState<'revenue' | 'tip'>('revenue');

    const showTip = !restaurant?.config?.disableTipForVendorUsers || false;
    const vendor_enableTipCommission = restaurant?.config?.vendor_enableTipCommission || false;
    const disableTipCardAndChart = restaurant?.restaurant_country?.config?.disableTipCardAndChart || false;
    const hideTip = vendor_enableTipCommission && disableTipCardAndChart;
    const isTransactionPoweredApi = restaurant?.config?.isTransactionPoweredApi || false;

    const showTipCard = showTip && !hideTip;
    const { from, to } = timeRange;
    const period = timeRange.type;

    const handleTabChange = (event: React.SyntheticEvent, newValue: 'revenue' | 'tip') => {
        setChartMode(newValue);
        onPushEvent(`user_select_tip_show_on_revenue_chart_to_${newValue || false}`);
    };

    const baseName = type === ReportType.RESTAURANT ? 'restaurant' : 'brand';
    const id = type === ReportType.RESTAURANT ? user.restaurantId : restaurant?.brand_id;

    useEffect(() => {
        if (
            (type === ReportType.BRAND && restaurant?.brand_id) ||
            (type === ReportType.RESTAURANT && user.restaurantId)
        ) {
            setLoading(true);
            dashboardService
                .getRevenueChartReport({
                    name: baseName,
                    period,
                    from,
                    to,
                    id,
                    funnel: selectedProductType,
                    isTransactionPoweredApi,
                })
                .then((res) => {
                    const resChartData = res.data;
                    const { revenueChart, currencyCode, currencySymbol } = resChartData;

                    if (type === ReportType.RESTAURANT) {
                        setChartData(resChartData);
                    } else {
                        // separating chart data for multiple restaurants in brand
                        const chartDataMapping: any = {};
                        const revenueMaxValue = Math.max(...(revenueChart || []).map((c: any) => Math.round(c.paid)));
                        const tipMaxValue = Math.max(...(revenueChart || []).map((c: any) => c.tip));

                        (revenueChart || []).forEach((c: any) => {
                            const details = {
                                date: covertUtcToRestaurantDateTime(c.date, 'DD MMM'),
                                revenue: parseFloat(c.paid),
                                revenue_with_tip: parseFloat(c.paidAmountWithTip),
                            };
                            if (chartDataMapping[c.restaurantUnique]) {
                                chartDataMapping[c.restaurantUnique].push(details);
                            } else {
                                chartDataMapping[c.restaurantUnique] = [details];
                            }
                        });

                        setChartData({
                            data: Object.values(chartDataMapping),
                            colors: randomColor({ count: Object.keys(chartDataMapping).length }),
                            restaurants: Object.keys(chartDataMapping) || [],
                            currencyCode: currencyCode || '',
                            currencySymbol: currencySymbol || '',
                            revenueMaxValue,
                            tipMaxValue,
                        });
                    }
                })
                .catch(() => {
                    setError(t('Something went wrong'));
                })
                .finally(() => setLoading(false));
        }
    }, [user, restaurant?.brand_id, selectedProductType, timeRange]);

    const skeletonRevenueChart = () => (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Skeleton variant="rectangular" sx={{ height: '300px', borderRadius: '8px' }} />
            </Grid>
        </Grid>
    );

    const withCommaSeparator = shouldCommafy(chartData?.currencyCode);

    return (
        <>
            {type === ReportType.RESTAURANT && (
                <Grid item xs={12}>
                    <Section
                        sx={{ borderColor: '#E5E5E5', borderWidth: '1px', borderRadius: '20px' }}
                        onMouseEnter={() => {
                            onPushEvent('User_hover_on_the_revenue_chart_to_see_the_number');
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: {
                                    sm: 'row',
                                    xs: 'column',
                                },
                                justifyContent: 'space-between',
                            }}
                        >
                            <Tabs value={chartMode} onChange={handleTabChange} variant="fullWidth">
                                <Tab value="revenue" label={t('Revenue')} />
                                {showTipCard && <Tab value="tip" label={t('Tips')} />}
                            </Tabs>
                        </Box>
                        <SkeletonLoadingContent loading={loading} error={error} skeleton={skeletonRevenueChart()}>
                            <ResponsiveContainer width="99%" height={300}>
                                {chartData && (
                                    <AreaChart
                                        data={chartData.revenueChart.map((d: any) => ({
                                            date: covertUtcToRestaurantDateTime(
                                                d.date,
                                                lang === 'ja' ? 'MMM DD[日]' : 'DD MMM',
                                            ),
                                            revenue:
                                                chartMode === 'tip'
                                                    ? (parseFloat(d.paidAmountWithTip) - parseFloat(d.paid)).toFixed(
                                                          getCurrencyPrecision(chartData.currencyCode),
                                                      )
                                                    : parseFloat(d.paid).toFixed(
                                                          getCurrencyPrecision(chartData.currencyCode),
                                                      ),
                                        }))}
                                        margin={{
                                            top: 40,
                                            right: 30,
                                            left: 0,
                                            bottom: 0,
                                        }}
                                    >
                                        <defs>
                                            <linearGradient id="colorRev" x1="0" y1="0" x2="0" y2="1">
                                                <stop offset="00%" stopColor="#7D00D4" />
                                                <stop offset="25%" stopColor="#7D00D4" />
                                                <stop offset="100%" stopColor="#C4C4C4" stopOpacity={0} />
                                            </linearGradient>
                                        </defs>
                                        <XAxis
                                            dataKey="date"
                                            tick={{
                                                fontSize: '14px',
                                            }}
                                        />
                                        <YAxis
                                            dataKey={(v) => parseInt(v.revenue, 10)}
                                            tick={{
                                                fontSize: '14px',
                                            }}
                                            axisLine={false}
                                            // change the showing of the y-axis if value is 60000 lets says show as 60,000
                                            tickFormatter={(value) => {
                                                if (withCommaSeparator) {
                                                    const cp = getCurrencyPrecision(chartData.currencyCode);
                                                    return `${commafy(value, cp)}`;
                                                }
                                                return value;
                                            }}
                                            width={withCommaSeparator ? 70 : undefined}
                                        />
                                        <Tooltip
                                            formatter={(value: any) => [
                                                `${getCurrencyWithAmount(
                                                    value,
                                                    chartData.currencySymbol,
                                                    chartData.currencyCode,
                                                    true,
                                                )}`,
                                                '',
                                            ]}
                                            separator=""
                                            cursor={{ stroke: '#000000', strokeWidth: 1, strokeDasharray: '10 5' }}
                                            contentStyle={{
                                                backgroundColor: '#FAFAFABF',
                                                borderRadius: '10px',
                                                borderWidth: '0px',
                                                minWidth: '120px',
                                                backdropFilter: 'blur(5px)',
                                                color: '#000000',
                                            }}
                                            itemStyle={{ color: '#000000' }}
                                        />
                                        <CartesianGrid stroke="#E5E5E5" strokeDasharray="0" vertical={false} />
                                        <Area
                                            type="monotone"
                                            dataKey="revenue"
                                            stroke="#7D00D4"
                                            strokeWidth={2}
                                            fillOpacity={1}
                                            fill="url(#colorRev)"
                                            activeDot={{
                                                stroke: '#0075FF',
                                                fill: '#0075FF',
                                            }}
                                        />
                                    </AreaChart>
                                )}
                            </ResponsiveContainer>
                        </SkeletonLoadingContent>
                    </Section>
                </Grid>
            )}
            {type === ReportType.BRAND && (
                <Grid item xs={12}>
                    <Section sx={{ borderColor: '#E5E5E5' }}>
                        <SkeletonLoadingContent loading={loading} error={error} skeleton={skeletonRevenueChart()}>
                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Tabs value={chartMode} onChange={handleTabChange} variant="fullWidth">
                                        <Tab value="revenue" label={t('Revenue')} />
                                        {showTipCard && <Tab value="tip" label={t('Tips')} />}
                                    </Tabs>
                                </Box>
                                <Grid container>
                                    <Grid item xs={10}>
                                        {chartData && (
                                            <ResponsiveContainer width="99%" height={300}>
                                                <LineChart
                                                    data={chartData.data?.[0]}
                                                    margin={{
                                                        top: 40,
                                                        right: 30,
                                                        left: 0,
                                                        bottom: 0,
                                                    }}
                                                >
                                                    <XAxis dataKey="date" />
                                                    <YAxis
                                                        domain={[
                                                            0,
                                                            chartMode === 'revenue'
                                                                ? chartData.revenueMaxValue
                                                                : chartData.tipMaxValue,
                                                        ]}
                                                    />
                                                    <Tooltip
                                                        formatter={(value: any, a: any, b: any, idx: number) =>
                                                            `${chartData.restaurants[idx]} ${chartData.currencySymbol} ${value}`
                                                        }
                                                    />
                                                    {chartData?.data.map((res: any, i: number) => (
                                                        <Line
                                                            type="monotone"
                                                            dataKey={(initialData) => {
                                                                const index = res.findIndex(
                                                                    (obj: any) => obj.date === initialData.date,
                                                                );

                                                                return chartMode === 'tip'
                                                                    ? (
                                                                          parseFloat(res?.[index]?.revenue_with_tip) -
                                                                          parseFloat(res?.[index]?.revenue)
                                                                      )?.toFixed(
                                                                          getCurrencyPrecision(chartData.currencyCode),
                                                                      )
                                                                    : res?.[index]?.revenue?.toFixed(
                                                                          getCurrencyPrecision(chartData.currencyCode),
                                                                      );
                                                            }}
                                                            stroke={chartData.colors[i]}
                                                            strokeWidth={2}
                                                            dot={false}
                                                        />
                                                    ))}
                                                </LineChart>
                                            </ResponsiveContainer>
                                        )}
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                height: '100%',
                                            }}
                                        >
                                            {chartData?.restaurants.map((res: any, i: number) => (
                                                <Box sx={{ display: 'flex', marginTop: '5px' }}>
                                                    <Box
                                                        sx={{
                                                            width: '15px',
                                                            height: '15px',
                                                            borderRadius: '30px',
                                                            backgroundColor: chartData.colors[i],
                                                            marginRight: '15px',
                                                            marginTop: '8px',
                                                            flex: 'none',
                                                        }}
                                                    />
                                                    <Typography sx={{ fontWeight: 600 }}>{res}</Typography>
                                                </Box>
                                            ))}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sx={{ marginTop: '2rem' }}>
                                <TableContent
                                    large
                                    heading={t(`{{period}} performance`, {
                                        period: `${period.charAt(0).toUpperCase()}${period.slice(1)}`,
                                    })}
                                    headCells={
                                        <>
                                            <TableCell>{t(`Restaurant's name`)}</TableCell>
                                            <TableCell>
                                                {t(`Revenue this {{period}}`, { period: period.slice(0, -2) })}
                                            </TableCell>
                                            {showTipCard && (
                                                <TableCell>
                                                    {t(`Tip this {{period}}`, { period: period.slice(0, -2) })}
                                                </TableCell>
                                            )}
                                        </>
                                    }
                                >
                                    {chartData?.restaurants.map((r: string, i: number) => (
                                        <RestaurantRow
                                            key={i}
                                            data={chartData.data?.[i]}
                                            name={r}
                                            color={chartData.colors[i]}
                                            currencySymbol={chartData.currencySymbol}
                                            currencyCode={chartData.currencyCode}
                                        />
                                    ))}
                                </TableContent>
                            </Grid>
                        </SkeletonLoadingContent>
                    </Section>
                </Grid>
            )}
        </>
    );
}
