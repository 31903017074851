import { useRestaurantContext } from '@/contexts/restaurant';
import { useTranslation } from '@/hooks/translations';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import DashboardService from '@/services/dashboard';
import { restrictRoles } from '@/constants/roleRestrictions';
import { useUserContext } from '@/contexts/user';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import { ReportType } from './types';
import DashboardCard from './DashboardCard';

export default function OrderAndRevenue() {
    const dashboardService = DashboardService.getInstance();

    const { user } = useUserContext();
    const { restaurant, selectedQrGroup } = useRestaurantContext();
    const { t } = useTranslation('common');
    const [loading, setLoading] = useState(false);
    const [revenueorderwow, setRevenueOrderWow] = useState<number>();
    const [ordersComparedLastWeek, setOrdersComparedLastWeek] = useState<number>();
    const [error, setError] = useState<string>('');

    const type = restrictRoles.BRAND_DASHBOARD.includes(user?.userData.role) ? ReportType.BRAND : ReportType.RESTAURANT;

    const baseName = type === ReportType.RESTAURANT ? 'restaurant' : 'brand';
    const id = type === ReportType.RESTAURANT ? user.restaurantId : restaurant?.brand_id;

    const selectedDasboardKeys = restaurant?.config?.selectedDashboardKeys || [];

    const orderAndRevenueKeys = [
        {
            key: 'revenueorderwow',
            name: 'Revenue & Order WoW',
            dateFilterText: t('Revenue compared last week'),
            tooltipKey: t('Revenue_Compared_to_Last_Week_TooltipText'),
            enabled: true,
        },
        {
            key: 'orders',
            name: 'Orders Compared Last Week',
            dateFilterText: t('Orders compared last week'),
            tooltipKey: t('Orders_Compared_to_Last_Week_TooltipText'),
            enabled: true,
        },
    ].map((item) => {
        return {
            ...item,
            enabled: selectedDasboardKeys.length === 0 ? item.enabled : selectedDasboardKeys.includes(item.key),
            valueIcon1: null,
        };
    });

    useEffect(() => {
        setLoading(true);
        dashboardService
            .getRevenueWowReport({ name: baseName, id, qrGroups: selectedQrGroup ? [selectedQrGroup] : null })
            .then((res) => setRevenueOrderWow(res.revenue_wow))
            .catch((err) => setError(err.message))
            .finally(() => setLoading(false));
        dashboardService
            .getOrderReportDiffWeekly({ name: baseName, id, qrGroups: selectedQrGroup ? [selectedQrGroup] : null })
            .then((res) => setOrdersComparedLastWeek(res.orders))
            .catch((err) => setError(err.message))
            .finally(() => setLoading(false));
    }, [baseName, id, selectedQrGroup]);

    return orderAndRevenueKeys.map((item) => {
        if (!item.enabled) return null;
        const value = (item.key === 'revenueorderwow' ? revenueorderwow : ordersComparedLastWeek) || 0;

        return (
            <Grid item xs={6} sm={6} md={3} lg={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <DashboardCard
                            headerText={item.dateFilterText}
                            value={`${
                                item.key === 'revenueorderwow' ? `${((value || 0) * 100).toFixed(2)} %` : value || 0
                            }`}
                            valueIcon={value > 0 ? <ArrowUpwardRoundedIcon /> : <ArrowDownwardRoundedIcon />}
                            tooltipText={item.tooltipKey}
                            loading={loading}
                            error={error}
                            type={'outlined'}
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    });
}
